import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

const Company = () => {
    const [allCompanies, setAllCompanies] = useState<any>([]);
    console.log(allCompanies,"allCompanies");
    
    const getAllCompany = async () => {
        try {
          const response = await axios.get(`${API_URL}/company/get/all/`);
          if (response.status === 200) {
            setAllCompanies(response.data);
          } else {
          }
        } catch (err) {
          toast.error(" Please Contact Administrator");
        }
      };

      useEffect(() => {
        getAllCompany();
      }, []);
    return (
        <div>
            <div
                className="breadcumb-wrapper"
                style={{ backgroundImage: "url('../assets/img/bg/breadcumb-bg.jpg')" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9">
                            <div className="breadcumb-content">
                                <h1 className="breadcumb-title">Developers</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="space-top space-extra-bottom">
                <div className="container">
                    <div className="row gy-30">
                        {allCompanies?.map((item)=>(
                            <div key={item?.id} className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src={item?.img} alt="Product Image" style={{height:"200px"}} />
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title text-dark"><a className='web_link' href={`/company/${item?.slug}`}>{item?.company_name}</a></h3>
                                    <div className="woocommerce-product-rating">
                                        <span className="count" style={{color:"#818589"}}>({item?.count_property} Properties)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                        
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Company