import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // const handleFormSubmit = () => {
  //   const { name, email, phone, description } = formData;

  //   // Validate form fields
  //   if (!name || !email) {
  //     toast.error('Name and Email are required.');
  //     return;
  //   }

  //   // Create a form data object
  //   const zohoFormData = new FormData();
  //   zohoFormData.append('xnQsjsdp', 'c7be8d2e2291eae29f957bb0b4600a72c1533345c5b4aad1fcf4b0f84aa2175b');
  //   zohoFormData.append('xmIwtLD', 'f576177da53b3d9780e2dc55a95598031c08bc88cc306f8695f297d73da42ead238108f269deb6351e26d0aa8c7946b5');
  //   zohoFormData.append('actionType', 'TGVhZHM=');
  //   zohoFormData.append('returnURL', 'https://www.pontis.ae');
  //   zohoFormData.append('Last Name', name);
  //   zohoFormData.append('Email', email);
  //   zohoFormData.append('Phone', phone);
  //   zohoFormData.append('Description', description);


  //   // Send form data to Zoho CRM
  //   fetch('https://crm.zoho.com/crm/WebToLeadForm', {
  //     method: 'POST',
  //     body: zohoFormData,
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         // alert('Form submitted successfully!');
  //         toast.success("We Will Contact You Soon");

  //         setFormData({
  //           name: '',
  //           email: '',
  //           phone: '',
  //           description: '',
  //         });
  //       } else {
  //         toast.error('Failed to submit form.');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error submitting form:', error);
  //       toast.error('An error occurred while submitting the form.');
  //     });
  // };


  const handleFormSubmit = async () => {
    const { name, email, phone, description } = formData;
  
    // Validate form fields
    if (!name || !email) {
      toast.error('Name and Email are required.');
      return;
    }
  
    try {
      // Create a form data object
      const zohoFormData = new FormData();
      zohoFormData.append('name', name);
      zohoFormData.append('email', email);
      zohoFormData.append('mobile', phone);
      zohoFormData.append('msg', description);
  
      // Send form data to Zoho CRM with axios
      const response = await axios.post(`${API_URL}/account/contact/create/`, zohoFormData);
  
      if (response.status === 200 || response.status === 201 ) {
        toast.success("We Will Contact You Soon");
  
        setFormData({
          name: '',
          email: '',
          phone: '',
          description: '',
        });
      } else {
        toast.error('Failed to submit form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('An error occurred while submitting the form.');
    }
  };
  return (
    <>
      <div
        className="breadcumb-wrapper"
        style={{
          backgroundImage: "url('../../assets/img/bg/breadcumb-bg.jpg')",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Contact</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space">
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title">Get In Touch</span>
            <h2 className="sec-title text-theme">Our Contact Information</h2>
          </div>
          <div className="row gy-4 justify-content-center">
            <div className="col-xl-4 col-lg-6 ">
              <div className="about-contact-grid style2 d-block tawc " >
                <div className="about-contact-icon mb-3">
                  <i className="fal fa-location-dot"></i>
                </div>
                <div className="about-contact-details mb-3">
                  <h6 className="about-contact-details-title mb-0">Dubai Branch</h6>
                  <p className="about-contact-details-text">
                    #130, Al Shafar Investment Building,<br /> Sheikh Zayed Road, Dubai, UAE
                  </p>
                </div>
                <div className="about-contact-details">
                  <h6 className="about-contact-details-title mb-0">Head Office Canada</h6>
                  <p className="about-contact-details-text">
                    7275 Rapistan Court,Mississauga,<br /> Ontario L5N 5Z4
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-contact-grid style2 d-block tawc" style={{ padding: "28px 0 93px 0px"}}>
                <div className="about-contact-icon mb-3">
                  <i className="fal fa-phone"></i>
                </div>
                <div className="about-contact-details mb-3">
                  <h6 className="about-contact-details-title mb-0">Contact Number</h6>
                  <p className="about-contact-details-text">
                    <a href="tel:+97148521168">+971-4852-1168</a>
                  </p>
                  <p className="about-contact-details-text">
                    <a href="tel:+19059522055">+1(905) 952-2055</a>
                  </p>

                </div>

              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-contact-grid style2 d-block tawc" style={{ padding: "28px 0 120px 0px"}}>
                <div className="about-contact-icon mb-3">
                  <i className="fal fa-envelope"></i>
                </div>
                <div className="about-contact-details mb-3">
                  <h6 className="about-contact-details-title mb-0">Email</h6>
                  <p className="about-contact-details-text">
                    <a href="mailto:dubai@pontisrealty.com">
                    info@pontis.ae
                    </a>
                  </p>
                  {/* <p className="about-contact-details-text">
                    <a href="mailto:dubai@pontisrealty.com">
                      info@pontisrealty.com
                    </a>
                  </p> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="space contact-area-3 z-index-common"
        // data-bg-src="assets/img/bg/contact-bg-1-1.png"
        style={{
          backgroundImage: "url('/assets/img/bg/contact-bg-1-1.png')",
        }}
        data-overlay="title"
        data-opacity="3"
      >
        {/* <div
          className="contact-bg-shape3-1 spin shape-mockup "
          data-bottom="5%"
          data-left="12%"
        >
          <img src="assets/img/shape/section_shape_2_1.jpg" alt="img" />
        </div> */}
        <div className="container">
          <div className="row gx-35">
            <div className="col-lg-6">
              <div className="appointment-wrap2 bg-white me-xxl-5">
                <h2 className="form-title text-theme">Schedule a visit</h2>
                <form
                  action="#"
                >
                  <div className="row">
                    <div className="form-group style-border style-radius col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Your Name*"
                      />
                      <i className="fal fa-user"></i>
                    </div>
                    <div className="form-group style-border style-radius col-12">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Your Email*"
                      />
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="form-group style-border style-radius col-12">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Your Contact*"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      <i className="fal fa-phone"></i>
                    </div>

                    <div className="col-12 form-group style-border style-radius">
                      <i className="far fa-comments"></i>
                      <textarea
                        placeholder="Type Your Message"
                        className="form-control"
                        value={formData.description}
                        onChange={handleInputChange}
                        id="description"
                      ></textarea>
                    </div>
                    <div className="col-12 form-btn mt-4">
                      <button className="th-btn" onClick={handleFormSubmit} type="button">
                        Submit Message{" "}
                        <span className="btn-icon">
                          <img
                            src="assets/img/icon/paper-plane.svg"
                            alt="img"
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  <p className="form-messages mb-0 mt-3"></p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="location-map contact-sec-map z-index-common">
          <div className="contact-map">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.5063640135295!2d55.22425067562108!3d25.1523746335144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6987c2e54cdf%3A0x6bf7f6d77db8eeb1!2sAl%20Shafar%20Investment%20Building!5e0!3m2!1sen!2sin!4v1725447463719!5m2!1sen!2sin" loading="lazy" ></iframe>
          </div>
          <div className="location-map-address bg-theme">
            <div className="thumb">
              <img src="assets/img/property/property_inner_1.jpg" alt="img" />
            </div>
            <div className="media-body">
              <h4 className="title">Address:</h4>
              <p className="text">#130, Al Shafar Investment Building,
              Sheikh Zayed Road, Dubai, UAE</p>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
