import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import Properties from './Pages/Properties';
import PropertyDetail from './Pages/PropertyDetail';
import CompanyDetail from './Pages/CompanyDetail';
import Team from './Pages/Team';
import TeamDetails from './Pages/TeamDetails';
import Company from './Pages/Company';
import Disclaimer from './Pages/Disclaimer';
import PrivacyPolicy from './Pages/PrivacyPolicy';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path='/aboutus' element={<AboutUs />} />
      <Route path='/team' element={<Team />} />
      <Route path="/properties" element={<Properties />} />
      <Route path='/contactus' element={<Contact />} />
      <Route path='/property/:slug' element={<PropertyDetail />} />
      <Route path='/details/:id' element={<TeamDetails />} />
      <Route path='/company/:slug' element={<CompanyDetail />} />
      <Route path='/company' element={<Company />} />
      <Route path='/disclaimer' element={<Disclaimer />} />
      <Route path='/privacypolicy' element={<PrivacyPolicy />} />
 </Routes>
  );
};

export default AppRoutes;
