import React from "react";
import Header from "../components/BasicComponents/Header";
import Footer from "../components/BasicComponents/Footer";

const AboutUs = () => {
  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">ABOUT US</h1>
                {/* <ul className="breadcumb-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>ABOUT US</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden space">
       
        <div className="container">
          <div className="about-page-wrap">
            <div className="row gy-40 justify-content-between align-items-center">
              
              <div className="col-lg-6">
                <div className="img-box3">
                  <div className="img1">
                    <img
                      src="assets/img/normal/image.png"
                      alt="About"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h2
                  className="sec-title text-theme mb-2"
                  // style={{ fontSize: "36px" }}
                >
                  Who We Are
                </h2>
                {/* <p className=" text-theme">
                  Get one-on-one customer service from experienced
                  professionals.
                </p> */}
                <p className="text-theme" style={{ fontSize: "18px" }}>
                  "PONTIS" is a latin noun meaning "bridge." at pontis, we
                  embody this concept by prioritizing both the process and the
                  person. our approach combines extensive experience with
                  world-class customer service, distinguishing us not just as
                  realtors, but as real estate consultants. above all, pontis
                  serves as a bridge, connecting the global real estate market
                  to meet your needs and aspirations - Sarbjit Bath"
                </p>
                {/* <p className="text-theme">
                  Our floor plan designs focus on three elements: natural light,
                  color, and clean air all qualities that support your wellbeing
                  and energy levels. When you walk into our homes, you’ll see
                  design that puts people first, and more importantly, you’ll
                  feel it.
                </p> */}
              </div>
              <div className="col-lg-6">
                <h2
                  className="sec-title text-theme mb-2"
                  /* style={{ fontSize: "36px" }} */
                >
                  BRIDGING REAL ESTATE GLOBALLY
                </h2>
                <p className="text-theme" style={{ fontSize: "18px" }}>
                  By sharing our expert knowledge and offering step-by-step
                  transparency, it is our goal to elevate your understanding of
                  real estate to enable you to make informed decisions.
                </p>
                <p className="text-theme" style={{ fontSize: "18px" }}>
                  Beyond providing in-depth and innovative real estate services
                  through a diverse set of offerings, It is our goal to not only
                  secure the house of your dreams but to be your long- term
                  partner in real estate now and in the future.
                </p>
                {/* <div className="about-wrap2 style-theme mt-50">
                  <div className="checklist style4">
                    <ul>
                      <li>
                        <img src="assets/img/icon/checkmark.svg" alt="img" />
                        Quality real estate services
                      </li>
                      <li>
                        <img src="assets/img/icon/checkmark.svg" alt="img" />
                        100% Satisfaction guarantee
                      </li>
                      <li>
                        <img src="assets/img/icon/checkmark.svg" alt="img" />
                        Highly professional team
                      </li>
                      <li>
                        <img src="assets/img/icon/checkmark.svg" alt="img" />
                        Dealing always on time
                      </li>
                    </ul>
                  </div>
                  <div className="call-btn">
                    <div className="icon-btn bg-theme">
                      <img src="assets/img/icon/phone.svg" alt="img" />
                    </div>
                    <div className="btn-content">
                      <h6 className="btn-title text-theme">Call Us 24/7</h6>
                      <span className="btn-text">
                        <a className="text-theme" href="tel:0123456789">
                          +01 234 56789
                        </a>
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="img-box3">
                  <div className="img1">
                    <img src="assets/img/normal/highrise.jpg" alt="About" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-sec-1 overflow-hidden space bg-theme">
        {/* <div
          className="sec-bg-shape2-1 text-white spin shape-mockup d-xl-block d-none"
          data-bottom="15%"
          data-left="12%"
        >
          <img src="assets/img/shape/section_shape_2_1.jpg" alt="img" />
        </div> */}
        {/* <div
          className="sec-bg-shape2-3 text-white jump shape-mockup d-xl-block d-none"
          data-bottom="0%"
          data-right="7%"
        >
          <img src="assets/img/shape/section_shape_2_3.jpg" alt="img" />
        </div> */}
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 mb-5">
              <div className="title-area">
                <h2 className="sec-title text-white">
                  Real Estate is more than just physical location its a dream we
                  build together
                </h2>
                <p className="text-light" style={{fontSize:"18px"}}>
                  We understand that each property and transaction is unique.
                  That's why we believe you should collaborate with
                  professionals who possess not just expertise and experience,
                  but also exceptional interpersonal skills. This ensures that
                  your specific needs are met while transactions are carried
                  out. Our team comprises exceptional agents complemented by an
                  outstanding marketing team, dedicated to effectively
                  showcasing your property to the public.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row justify-content-lg-between align-items-center ">
                <div className="service-card style4 col-lg-6 mb-3">
                  <div className="">
                    <img
                      src="assets/img/icon/house.png"
                      alt="Icon"
                      style={{width: "80px", height: "80px"}}
                    />
                  </div>

                  <h3 className="box-title">
                    <a href="/">RESIDENTIAL</a>
                  </h3>
                  <p className="box-text" style={{fontSize:"18px"}}>
                    With a combination of a experience & exclusive market
                    insight, our residential division ensures a smooth move no
                    matter where your heading.
                  </p>
                </div>
                <div className="service-card style4 col-lg-6">
                  <div className="">
                    <img src="assets/img/icon/skyline.png" alt="Icon"  style={{width: "80px", height: "80px"}} />
                  </div>
                  <h3 className="box-title">
                    <a href="/">COMMERCIAL</a>
                  </h3>
                  <p className="box-text" style={{fontSize:"18px"}}>
                    At Pontis Real Estate we specialize in all things commercial,
                    whether you're investing or hunting down that perfect spot
                    for your business.
                  </p>
                </div>
              </div>
              <div className="row justify-content-lg-between align-items-center ">
                <div className="service-card style4 col-lg-6">
                  <div className="">
                    <img src="assets/img/icon/building.png" alt="Icon"  style={{width: "80px", height: "80px"}} />
                  </div>
                  <h3 className="box-title">
                    <a href="/">NEW DEVELOPMENTS</a>
                  </h3>
                  <p className="box-text" style={{fontSize:"18px"}}>
                    Be the first to hear about brand new developments all over
                    Ontario, get exclusive pricing and incentives through
                    Pontis.
                  </p>
                </div>
                <div className="service-card style4 col-lg-6">
                  <div className="">
                    <img src="assets/img/icon/land.png" alt="Icon"  style={{width: "80px", height: "80px"}} />
                  </div>
                  <h3 className="box-title">
                    <a href="/aboutus">LAND ACQUISITION</a>
                  </h3>
                  <p className="box-text" style={{fontSize:"18px"}}>
                    Trust our team's extensive network and experience in
                    securing the ideal land for development, investment, or
                    personal use.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-auto">
              <div className="sec-btn">
                <a
                  href="contact.html"
                  className="th-btn style-border th-btn-icon"
                >
                  Contact Us
                </a>
              </div>
            </div> */}
          </div>
          {/* <div
            className="swiper th-slider"
            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="service-card style4">
                  <div className="service-card-icon">
                    <img src="assets/img/icon/service-icon4-1.svg" alt="Icon" />
                  </div>
                  <h3 className="box-title">
                    <a href="property-details.html">Property Valuation</a>
                  </h3>
                  <p className="box-text">
                    Generous amounts of south facing glazing maximize the solar
                    gains for most of the year.
                  </p>
                  <div className="service-img img-shine">
                    <img src="assets/img/service/1-1.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="service-card style4">
                  <div className="service-card-icon">
                    <img src="assets/img/icon/service-icon4-2.svg" alt="Icon" />
                  </div>
                  <h3 className="box-title">
                    <a href="property-details.html">Property Management</a>
                  </h3>
                  <p className="box-text">
                    All living, dining, kitchen and play areas were devised by
                    attached to the home.
                  </p>
                  <div className="service-img img-shine">
                    <img src="assets/img/service/1-2.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="service-card style4">
                  <div className="service-card-icon">
                    <img src="assets/img/icon/service-icon4-1.svg" alt="Icon" />
                  </div>
                  <h3 className="box-title">
                    <a href="property-details.html">Invest Opportunities</a>
                  </h3>
                  <p className="box-text">
                    All-inclusive real estate services to facilitate the easy
                    management of your properties.
                  </p>
                  <div className="service-img img-shine">
                    <img src="assets/img/service/1-3.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="overflow-hidden space">
        <div className="container">
          <div className="about-page-wrap">
            <div className="title-area text-center">
              <h2 className="sec-title">Why Pontis Real Estate Was Founded</h2>
              <p className="sec-text">
                Noticing a gap in the industry of Commercial & Residential
                Brokerages we wanted to create a one stop shop for both agents
                and their clients.
              </p>
            </div>
            <div
              className="swiper-slide text-center "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="service-card style3 ">
                    <div
                      style={{
                        height: "96px",
                        width: "96px",
                        margin: "0 auto",
                      }}
                    >
                      <img src="assets/img/icon/mission.png" alt="icon" />
                    </div>
                    <h3 className="box-title">
                      <a href="/">OUR MISSION </a>
                    </h3>
                    <p className="box-text" style={{fontSize:"18px"}}>
                      At Pontis Real Estate, we're dedicated to delivering
                      exceptional service through personal touch and experience.
                      We equip our agents with the resources and knowledge to
                      best serve their clients.
                    </p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="service-card style3 ">
                    <div
                      style={{
                        height: "96px",
                        width: "96px",
                        margin: "0 auto",
                      }}
                      className="align-items-center "
                    >
                      <img src="assets/img/icon/vision.png" alt="icon" />
                    </div>
                    <h3 className="box-title">
                      <a href="/">OUR VISION</a>
                    </h3>
                    <p className="box-text" style={{fontSize:"18px"}}>
                      To create a one stop shop for all things Commercial &
                      Residential Real Estate globally while maintaining a
                      traditional approach to customer service.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="service-card style3 ">
                    <div
                      style={{
                        height: "96px",
                        width: "96px",
                        margin: "0 auto",
                      }}
                    >
                      <img src="assets/img/icon/worldwide.png" alt="icon" />
                    </div>
                    <h3 className="box-title">
                      <a href="/">OUR CULTURE </a>
                    </h3>
                    <p className="box-text" style={{fontSize:"18px"}}>
                      At Pontis Real Estate, we nurture an environment built on
                      mutual respect and openness. Being a boutique brokerage,
                      we pick our team members thoughtfully, ensuring their
                      values match ours.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="service-card style3 ">
                    <div
                      style={{
                        height: "96px",
                        width: "96px",
                        margin: "0 auto",
                      }}
                    >
                      <img src="assets/img/icon/personalized.png" alt="icon" />
                    </div>
                    <h3 className="box-title">
                      <a href="/">PERSONALIZED SERVICE </a>
                    </h3>
                    <p className="box-text" style={{fontSize:"18px"}}>
                      Whether you're buying or selling a home, seeking a space
                      for your business, or aiming to invest in real estate,
                      it's a significant transaction! We understand the
                      magnitude of this decision and aim to ensure you feel at
                      ease while making this life-changing choice
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="overflow-hidden space">
        <div className="sec-bg-shape2-1 spin shape-mockup d-xxl-block d-none" data-bottom="8%" data-left="8%">
            <img src="assets/img/shape/section_shape_2_1.jpg" alt="img"/>
        </div>
        <div className="container">
            <div className="row justify-content-lg-between justify-content-center align-items-center">
                <div className="col-xxl-6 col-lg-7">
                    <div className="title-area text-lg-start text-center">
                        <h2 className="sec-title text-theme">What Our Customers Says</h2>
                        <p className="sec-text text-theme">Realar help you easily create a real estate trading website. With the function Register, Login, Post real estate news.</p>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="sec-btn">
                        <div className="icon-box">
                            <button data-slider-prev="#testiSlider2" className="slider-arrow style5 default slider-prev"><img src="assets/img/icon/arrow-left.svg" alt=""/></button>
                            <button data-slider-next="#testiSlider2" className="slider-arrow style5 default slider-next"><img src="assets/img/icon/arrow-right.svg" alt=""/></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="testi-wrap2">
                <div className="swiper th-slider testi-slider2" id="testiSlider2" data-slider-options='{"spaceBetween":"48","breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"1"},"1200":{"slidesPerView":"2"}}}'>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="testi-grid-wrap2">
                                <div className="testi-grid-thumb">
                                    <img src="assets/img/testimonial/testi_thumb_2_1.png" alt="img"/>
                                </div>
                                <div className="testi-card style2">
                                    <div className="testi-grid_review">
                                        <i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i>
                                    </div>
                                    <p className="testi-card_text">"Home is where love resides, memories are created, and dreams are nurtured. I have found my sanctuary in this beautiful property. Finding the perfect that resonates with your own"</p>
                                    <div className="testi-card_profile">
                                        <div className="quote-icon">
                                            <img src="assets/img/icon/qoute2.svg" alt="icon"/>
                                        </div>
                                        <div className="avatar">
                                            <img src="assets/img/testimonial/testi_2_1.png" alt="avatar"/>
                                        </div>
                                        <div className="testi-card_profile-details">
                                            <h3 className="testi-card_name">Andrew Simon</h3>
                                            <span className="testi-card_desig">Property Expert</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testi-grid-wrap2">
                                <div className="testi-grid-thumb">
                                    <img src="assets/img/testimonial/testi_thumb_2_2.png" alt="img"/>
                                </div>
                                <div className="testi-card style2">
                                    <div className="testi-grid_review">
                                        <i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i>
                                    </div>
                                    <p className="testi-card_text">"Home is where love resides, memories are created, and dreams are nurtured. I have found my sanctuary in this beautiful property. Finding the perfect that resonates with your own"</p>
                                    <div className="testi-card_profile">
                                        <div className="quote-icon">
                                            <img src="assets/img/icon/qoute2.svg" alt="icon"/>
                                        </div>
                                        <div className="avatar">
                                            <img src="assets/img/testimonial/testi_2_2.png" alt="avatar"/>
                                        </div>
                                        <div className="testi-card_profile-details">
                                            <h3 className="testi-card_name">Ralph Edwards</h3>
                                            <span className="testi-card_desig">Property Expert</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testi-grid-wrap2">
                                <div className="testi-grid-thumb">
                                    <img src="assets/img/testimonial/testi_thumb_2_1.png" alt="img"/>
                                </div>
                                <div className="testi-card style2">
                                    <div className="testi-grid_review">
                                        <i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i>
                                    </div>
                                    <p className="testi-card_text">"Home is where love resides, memories are created, and dreams are nurtured. I have found my sanctuary in this beautiful property. Finding the perfect that resonates with your own"</p>
                                    <div className="testi-card_profile">
                                        <div className="quote-icon">
                                            <img src="assets/img/icon/qoute2.svg" alt="icon"/>
                                        </div>
                                        <div className="avatar">
                                            <img src="assets/img/testimonial/testi_2_1.png" alt="avatar"/>
                                        </div>
                                        <div className="testi-card_profile-details">
                                            <h3 className="testi-card_name">Andrew Simon</h3>
                                            <span className="testi-card_desig">Property Expert</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testi-grid-wrap2">
                                <div className="testi-grid-thumb">
                                    <img src="assets/img/testimonial/testi_thumb_2_2.png" alt="img"/>
                                </div>
                                <div className="testi-card style2">
                                    <div className="testi-grid_review">
                                        <i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i><i className="fa-sharp fa-solid fa-star"></i>
                                    </div>
                                    <p className="testi-card_text">"Home is where love resides, memories are created, and dreams are nurtured. I have found my sanctuary in this beautiful property. Finding the perfect that resonates with your own"</p>
                                    <div className="testi-card_profile">
                                        <div className="quote-icon">
                                            <img src="assets/img/icon/qoute2.svg" alt="icon"/>
                                        </div>
                                        <div className="avatar">
                                            <img src="assets/img/testimonial/testi_2_2.png" alt="avatar"/>
                                        </div>
                                        <div className="testi-card_profile-details">
                                            <h3 className="testi-card_name">Ralph Edwards</h3>
                                            <span className="testi-card_desig">Property Expert</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="space bg-theme">
        <div className="sec-bg-shape2-3 jump shape-mockup d-xxl-block d-none text-white" data-bottom="5%" data-right="8%">
            <img src="assets/img/shape/section_shape_2_3.jpg" alt="img"/>
        </div>
        <div className="container">
            <div className="row justify-content-lg-between align-items-center">
                <div className="col-lg-6">
                    <div className="title-area">
                        <h2 className="sec-title text-white">Meet The Awesome Team</h2>
                        <p className="text-light">Realar help you easily create a real estate trading website. With the function Register, Login, Post real estate news.</p>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="sec-btn">
                        <a href="team.html" className="th-btn style-border th-btn-icon">View All Team</a>
                    </div>
                </div>
            </div>
            <div className="slider-area team-slider3">
                <div className="swiper th-slider" id="teamSlider3" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1400":{"slidesPerView":"3"}}}'>
                    <div className="swiper-wrapper">
                       
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_1.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Janny Wilson</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                        
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_2.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Andrew Richard</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                       
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_3.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Zarin Wilson</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                      
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_4.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Michel Smith</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                      
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_1.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Janny Wilson</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                       
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_2.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Andrew Richard</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                     
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_3.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Zarin Wilson</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                      
                        <div className="swiper-slide">
                            <div className="th-team team-card style3">
                                <div className="img-wrap">
                                    <div className="team-img">
                                        <img src="assets/img/team/team_2_4.png" alt="Team"/>
                                    </div>
                                    <div className="th-social-wrap">
                                        <div className="th-social">
                                            <a target="_blank" href="https://facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a>
                                            <a target="_blank" href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                            <a target="_blank" href="https://youtube.com/"><i className="fab fa-youtube"></i></a>
                                            <a target="_blank" href="https://instagram.com/"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <a className="icon-btn" href="team-details.html"><img src="assets/img/icon/arrow-right.svg" alt="img"/></a>
                                    </div>
                                </div>
                                <div className="team-card-content">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="team-details.html">Michel Smith</a></h3>
                                        <span className="team-desig">Property Expert</span>
                                    </div>
                                    <a className="icon-btn" href="tel:09876543210"><img src="assets/img/icon/phone.svg" alt="img"/></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <button data-slider-prev="#teamSlider3" className="slider-arrow style6 slider-prev"><img src="assets/img/icon/arrow-left.svg" alt="icon"/></button>
                <button data-slider-next="#teamSlider3" className="slider-arrow style6 slider-next"><img src="assets/img/icon/arrow-right.svg" alt="icon"/></button>
            </div>
        </div>
    </section>


    <div className="client-area-1 space">
        <div className="container">
            <div className="slider-area client-slider1">
                <div className="swiper th-slider has-shadow" id="clientSlider1" data-slider-options='{"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"3"},"768":{"slidesPerView":"4"},"992":{"slidesPerView":"5"},"1200":{"slidesPerView":"6"}}}'>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_1.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_2.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_3.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_4.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_5.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_6.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_1.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_2.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_3.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_4.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_5.svg" alt="Image"/>
                            </a>
                        </div>

                        <div className="swiper-slide">
                            <a href="#" className="client-card">
                                <img src="assets/img/brand/brand_2_6.svg" alt="Image"/>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </div>
  );
};

export default AboutUs;
