import React, { useState } from 'react'
import AutoComplete from "react-google-autocomplete";
interface Location {
  geometry?: {
    location?: {
      lat: () => number;
      lng: () => number;
    };
  };
}
interface SearchLocationProps {
  onLocationSelect: (location: Location) => void;
}
const SearchLocation: React.FC<SearchLocationProps> = ({ onLocationSelect }) => {
  const [location, setLocation] = useState<Location | null>(null);
  const handlePlaceSelected = (place: Location) => {
    setLocation(place);
    onLocationSelect(place); // Pass location to parent
  };
  return (
    <>
   <AutoComplete
                  apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                  onPlaceSelected={handlePlaceSelected}
                //   onKeyPress={handleKeyPress}
                  className="form-control"
                  placeholder="Enter Location..."
                  onKeyDown={(event) => {
                    if (event?.keyCode === 18) {
                      event?.preventDefault();
                    }
                  }}
                  options={{
                    types: ["route"],
                    componentRestrictions: { country: 'ae' },

                  }}

                />
    </>
  )
}

export default SearchLocation