import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/BasicComponents/Header';
import AppRoutes from './AppRoute';
import Footer from './components/BasicComponents/Footer';
import "react-toastify/dist/ReactToastify.css";
import Loader from './components/BasicComponents/loader';
import './App.css'
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
    <Router>
      {/* <Loader /> */}
      <Header />
      <div className="bodytoppart">
        <AppRoutes />
      </div>
      <Footer />
    </Router>
    <ToastContainer />
    </>
  );
};

export default App;
