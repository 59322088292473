import React, { useEffect, useState } from "react";
import stickyLogo from "../../images/ponties.png";
import logo from "../../images/new_dubai.png";
import { useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [show, setShow] = useState(false);
  const [buildingType, setBuildingType] = useState<any>([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

const offPlan=()=>{
  navigate("/properties", { state: {is_property:true } });
  handleClose();
}
const allProperties=()=>{
  navigate("/properties", { state: {} }); 
  handleClose();
}
const contactForm = () => {
  navigate("/"); // Navigate to the home page where #contact-form exists
  setTimeout(() => {
    const element = document.getElementById("contact-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 0);
};
useEffect(() => {
  
  getBuildType();
 
}, []);
const commericalFunction = async (id) => {
  try {
    const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

    navigate("/properties", { state: { allcommerical, building_type: buildingType[0]?.id } });
    handleClose();
  } catch (error) {
    console.error("Error fetching properties:", error);
  }
};
const residentialFunction = async (id) => {
  try {
    const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

    navigate("/properties", { state: { allcommerical, building_type: buildingType[1]?.id } });
    handleClose();
  } catch (error) {
    console.error("Error fetching properties:", error);
  }
};
const offPlanFunction = async () => {
  try {

    navigate("/properties", { state: {  is_property: true } });
    handleClose();
  } catch (error) {
    console.error("Error fetching properties:", error);
  }
};
const getCommericalProperties = async (id) => {

  try {
    let apiUrl = `${API_URL}/properties/advance/filter/?building_type=${id}`;
    const response = await axios.get(apiUrl);
    if (response.status === 200) {
      const responseData = response.data;
      // setAllProperties(responseData);
      ;
      return responseData
    } else {
      // Handle unexpected status codes, if needed
    }
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching properties:", error);
    throw error; // Re-throw the error to be caught by the caller
  }
};
  return (
    <>
    
    <Offcanvas show={show} onHide={handleClose}>
       
        <Offcanvas.Body>
        
          
            <div className="mobile-logo">
                <a href="/">
                <img
                      src={stickyLogo}
                      alt="Ponties"
                      // style={{ width: "150px" }}
                    />
                </a>
            </div>
            <div className="th-mobile-menu">
                <ul>
                <li className="menu-item-has-children">
                      <a  style={{cursor:"pointer"}}onClick={()=>allProperties()}>PROPERTIES</a>
                     </li>
                     <li className="menu-item-has-children">
                      <a href="/aboutus">ABOUT US</a>
 </li>
                    <li>
                      <a  style={{cursor:"pointer"}} onClick={()=>offPlan()}>OFF PLAN</a>
                    </li>
                    <li>
                    <a href="/team">PONTISIANS</a>
                    </li>
                    
                    <li>
                      <a href="/contactus">CONTACT US</a>
                    </li>
                   
                    <li>
                    <a  style={{cursor:"pointer"}} onClick={() => residentialFunction(buildingType[1]?.id)} >
                    RESIDENTIAL
                  </a>
                  </li>
                  <li>
                  <a  style={{cursor:"pointer"}}
                    onClick={() => commericalFunction(buildingType[0]?.id)}
                  >
                    COMMERCIAL
                  </a>
                  
                  </li>
                 
                  <li>
                    <a
                    id="request_button"
                    href="https://main.dn4kknqkhploe.amplifyapp.com/"
                    className=" style2 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SIGN IN
                  </a>
                    </li>
                </ul>
            </div>
        







        </Offcanvas.Body>
      </Offcanvas>
    
    <header className="th-header header-layout2">
      <div className="sticky-wrapper">
        <div className="menu-area">
          <div
            className="container-fluid"
            style={{ paddingLeft: "60px", paddingRight: "60px" ,background:"#fff"}}
          >
            <div className="row align-items-center justify-content-between ">
              <div className="col-auto">
                <div className="">
                  <a href="/">
                    <img
                    className="logo-img"
                      src={stickyLogo}
                      alt="Ponties"
                      // style={{ width: "200px" }}
                      
                    />
                  </a>
                </div>
              </div>
              <div className="col-auto fs-18">
                <nav className="main-menu d-none d-lg-inline-block">
                  <ul>
                    <li className="menu-item-has-children">
                      <a style={{cursor:"pointer"}}onClick={()=>allProperties()}>PROPERTIES</a>
                     </li>
                    <li className="menu-item-has-children">
                      <a href="/aboutus">ABOUT US</a>

                    
                    </li>
                    <li>
                      <a style={{cursor:"pointer"}} onClick={()=>offPlan()}>OFF PLAN</a>
                    </li>
                    <li>
                      <a href="/team">PONTISIANS</a>
                    </li>
                    
                    <li>
                      <a href="/contactus">CONTACT US</a>
                    </li>
                    
                  </ul>
                </nav>
               
              </div>
              <div className="col-auto d-xxl-block">
                <div className="header-button d-none d-lg-block">
                  <a
                    id="request_button"
                    href="https://dashboard.pontis.ae/"
                    className="th-btn style2 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Login to Pontis
                  </a>
                </div>
                <div className="header-button d-flex d-lg-none">
                  <button type="button" className="th-menu-toggle sidebar-btn" onClick={handleShow}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  );
};  

export default Header;
