import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

const Team = () => {
    const [pontiTeam, setPontiTeam] = useState<any>([]);
    console.log(pontiTeam, "ponties");

    const getPontiTeam = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/account/get/user/root/team/9a5be19e-38a3-4be0-9e27-8a55aa7086de`
            );
            if ((response.status = 200)) {
                const responseData = response.data;
                setPontiTeam(responseData);
            } else {
            }
        } catch (err) {
            toast.error(" Please Contact Administrator");
        }
    };
    useEffect(() => {
        getPontiTeam();
    }, []);

    return (
        <div id="ponti-team">
            <div
                className="breadcumb-wrapper"
                style={{
                    backgroundImage: "url('../../assets/img/teambanner.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", padding: "314px 0"
                }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9">
                            <div className="breadcumb-content">
                                <h1 className="breadcumb-title"></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="space">
                <div className="container z-index-common">
                    <div className="row gy-30">
                        {pontiTeam?.map((item) => (
                          
                            <div className="col-lg-4 col-md-6" key={item?.id}>
                                  <a href={`/details/${item?.id}`} >
                                <div className="th-team team-card style4">
                                    <div className="img-wrap">
                                        <div className="team-img">
                                            <img src={item?.profile} alt="Team" />
                                        </div>
                                    </div>
                                    <div className="team-card-content">
                                        <div className="media-left">
                                            <h3 className="box-title"><a href={`/details/${item?.id}`}>{item?.first_name}{" "}{item?.last_name}</a></h3>
                                            <span className="team-desig">{item?.user_role}</span>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                           
                        ))}

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Team