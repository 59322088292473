import React from "react";
import Header from "../components/BasicComponents/Header";
import Footer from "../components/BasicComponents/Footer";

const Disclaimer = () => {
  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Disclaimer</h1>
                {/* <ul className="breadcumb-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>ABOUT US</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden space">
        <div className="container">
          <div className="about-page-wrap">
            <div className="row gy-40 justify-content-between align-items-center">
              <p>
                This website and its components are solely for informational
                purposes. Neither we nor any third parties guarantee the
                accuracy, timeliness, performance, completeness, or suitability
                of the information and materials provided. We disclaim
                responsibility for the accuracy, usefulness, or availability of
                transmitted information and any errors or omissions therein.
                Information on this website is subject to change without notice.
                Your use of information or materials on this website is entirely
                at your own risk. You are responsible for ensuring that any
                products, services, or information obtained through this website
                meet your specific requirements.
              </p>
              <ul>
                <li>
                  1. This website uses cookies to monitor browsing preferences.
                  These small pieces of information, including personal details
                  stored by your browser, may be utilized by us or related third
                  parties. Personal information includes User Name, Password,
                  and E-mail Address used during registration. Registration
                  forms on our website collect contact, unique identifiers, and
                  demographic information which will be stored and used within
                  our brokerage for informational purposes.
                </li>
                <li>
                  2. All non-owned trademarks acknowledged on this website are
                  recognized.
                </li>
                <li>
                  3. Unauthorized website usage may result in claims for damages
                  and/or criminal offense charges.
                </li>
                <li>
                  4. Periodically, this website may provide links to other
                  websites for further information, but this does not signify
                  our endorsement or responsibility for their content. We
                  reserve the right to add, modify, or remove any material on
                  the website at our discretion. Your use of this website and
                  any resulting disputes are subject to Canadian laws.
                </li>
              </ul>
              <p>
                We disclaim liability for any loss or damage arising from the
                use of this website, including indirect or consequential losses,
                data loss, or profit loss. We are not accountable for the
                content, nature, or availability of websites linked beyond our
                control. While efforts are made to maintain website
                functionality, we cannot be held responsible for temporary
                unavailability due to technical issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
