import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const API_URL = process.env.REACT_APP_API_URL;

const CompanyDetail = () => {
  const companyslug = useParams();
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [relatingProperty, setRelatingProperty] = useState<any[]>([]);
  const [teamData, setTeamData] = useState<any[]>([]);


  const getCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/company/detail/${companyslug?.slug}/patch/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setCompanyDetail(responseData);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  const getAllProperties = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/pagination/?company=${companyDetail?.id}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setRelatingProperty(responseData?.results?.slice(0, 5));
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };


  const getTeam = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/account/company/user/${companyDetail?.id}/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setTeamData(responseData?.filter((item) => item?.is_developer === false));
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  useEffect(() => {
    if (companyslug?.slug) {
      getCompany();
    }
  }, [companyslug?.slug]);

  useEffect(() => {
    if (companyDetail?.id) {
      getAllProperties();
      getTeam();
    }
  }, [companyDetail?.id]);


  var companyList = {
    dots: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFormSubmit = () => {
    const { name, email, phone, description } = formData;

    // Validate form fields
    if (!name || !email) {
      toast.error("Name and Email are required.");
      return;
    }

    // Create a form data object
    const zohoFormData = new FormData();
    zohoFormData.append(
      "xnQsjsdp",
      "c7be8d2e2291eae29f957bb0b4600a72c1533345c5b4aad1fcf4b0f84aa2175b"
    );
    zohoFormData.append(
      "xmIwtLD",
      "f576177da53b3d9780e2dc55a95598031c08bc88cc306f8695f297d73da42ead238108f269deb6351e26d0aa8c7946b5"
    );
    zohoFormData.append("actionType", "TGVhZHM=");
    zohoFormData.append("returnURL", "https://www.pontis.ae");
    zohoFormData.append("Last Name", name);
    zohoFormData.append("Email", email);
    zohoFormData.append("Phone", phone);
    zohoFormData.append("Description", description);

    // Send form data to Zoho CRM
    fetch("https://crm.zoho.com/crm/WebToLeadForm", {
      method: "POST",
      body: zohoFormData,
    })
      .then((response) => {
        if (response.status === 200) {
          // alert('Form submitted successfully!');
          toast.success("We Will Contact You Soon");

          setFormData({
            name: "",
            email: "",
            phone: "",
            description: "",
          });
        } else {
          toast.error("Failed to submit form.");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("An error occurred while submitting the form.");
      });
  };

  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('../assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">
                  {companyDetail?.company_name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row gx-30">
            <div className="col-xxl-8 col-lg-7">
              <div className="agency-page-single">
                <div className="page-content">
                  <div className="agency-page-img">
                    <div className="thumb">
                      <img
                        src="../assets/img/agency/agency_inner_1.jpg"
                        alt="Blog Image"
                      />
                    </div>
                    <div className="agency-card-brand">
                      <img src={companyDetail?.img} alt="img" />
                    </div>
                  </div>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6">
                      <h2 className="page-title h4 text-theme mb-0 mt-15">
                        {companyDetail?.company_name}
                      </h2>
                      <p className="text-theme">
                        {companyDetail?.street_address}
                      </p>
                    </div>
                    {/* <div className="col-lg-auto">
                      <h4 className="text-theme h5 mb-2">Contact Info</h4>
                      <p className="mb-0 text-theme">
                        <i className="far fa-phone me-2"></i>
                        <strong className="fw-medium">
                          {companyDetail?.office_number},
                          {companyDetail?.mobile_number}
                        </strong>
                      </p>
                      <p className="mb-0 text-theme">
                        <i className="fa-solid fa-earth-americas"></i>
                        <strong className="fw-medium">{" "}
                          <a className="web_link" href={companyDetail?.company_website} target="blank">
                          {companyDetail?.company_website}
                          </a>
                         </strong>
                      </p>
                    </div> */}
                  </div>

                  <p className="mb-30 text-theme mt-40">
                    {companyDetail?.company_description}
                  </p>
                  {teamData?.length > 0 ? (<>
                    <div className="row align-items-center justify-content-between">
                      <div className="col-lg-auto">
                        <h4 className="text-theme mb-40">Agents</h4>
                      </div>
                      <div className="" id="clientSlider1">
                        <div className="row">
                          {teamData?.map((item) => (
                            <div className="col-2 text-center">
                              <Link to={`/company/${item?.slug}`} className="client-card">
                                <img src={item?.profile} alt="Image" />
                              </Link>
                              <h3 style={{ fontSize: "12px", padding: "10px 0" }}>
                                {item?.first_name}{' '}{item?.last_name}<br />
                                {item?.user_role}
                              </h3>

                            </div>
                          ))}

                        </div>
                      </div>
                    </div>
                  </>) : null}
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area">
                <div
                  className="widget widget-property-contact"
                  style={{ background: "#EBEBEB", borderRadius: "10px" }}
                >
                  <p className="widget_text" style={{ color: "#1C2D37" }}>
                    I am interested
                  </p>
                  <form
                    action="#"
                    className="widget-property-contact-form"
                    id="customForm"
                  >
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Email Address"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Phone Number"
                        id="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group ">
                      <textarea
                        name="Description"
                        id="description"
                        style={{
                          border: "1px solid #1C2D37",
                          borderRadius: "25px",
                        }}
                        placeholder="Type Your Message"
                        className="form-control placeholder-style "
                        value={formData.description}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      className="th-btn text-white mt-15"
                      onClick={handleFormSubmit}
                    >
                      SEND
                    </button>
                  </form>
                </div>
                <div
                  className="widget  "
                  style={{ background: "#EBEBEB", borderRadius: "10px" }}
                >
                  <h3 className="widget_title" style={{ color: "#1C2D37" }}>
                    Featured Listing
                  </h3>
                  <div className="recent-post-wrap">
                    {relatingProperty?.map((item) => (
                      <div className="recent-post" key={item?.id}>
                        <div className="media-img">
                          <a href={`/property/${item?.slug}`}>
                            <img
                              style={{ height: "80px" }}
                              src={item?.upload_featured_image}
                              alt="Blog Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <h4
                            className=""
                            style={{ color: "#1C2D37", fontSize: "18px" }}
                          >
                            <a className="" href={`/property/${item?.slug}`}>
                              {item?.property_name}
                            </a>
                          </h4>
                          <div className="recent-post-meta">
                            {item?.building_type_name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyDetail;
